import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPaymentStatus } from '../../../actions/payment';
import { useTranslation } from 'react-i18next';
import { PaymentPlatformContext } from '../PaymentPlatformProvider';

/*
 * Feature component
 *
 * Responsible to render the dialog content with the error details.
 *
 * - Responsible to handle the resetPaymentStatus reducer action and onClose event.
*/
const ErrorPaymentDialogContent = ({}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { error } = useSelector((state) => state.payment);
  const { onClose } = useContext(PaymentPlatformContext);
 
  const handleTryAgainButton = () => {
    dispatch(resetPaymentStatus());
  }
  const handleClose = () => {
    dispatch(resetPaymentStatus());
    onClose();
  }
  let errorMessage = i18n.t("common:text.unknown_payment_error");
  if (error["code"]) {
    errorMessage = i18n.t(`common:stripe.${error["code"]}`);
  }
  if (errorMessage === `stripe.${error["code"]}`) {
    // TODO: Afegir un sentry, en aquest cas vol dir que falta afegir la tradducció o que stripe 
    // ha fer refactor dels code errors
    errorMessage = i18n.t("common:text.unknown_payment_error");
  }

  return (
    <>
      <DialogTitle>
        { i18n.t("common:text.payment_error") }
      </DialogTitle>
      <DialogContent>
        <Alert severity={"error"}>
            { errorMessage }
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          { i18n.t("common:text.payment_form_close") }
        </Button>
        <Button color="primary" variant="contained" onClick={handleTryAgainButton}>
            { i18n.t("common:text.payment_form_try_again") }
        </Button>
      </DialogActions>
    </>
  )
}


export default ErrorPaymentDialogContent;
