import { useEffect, useState } from 'react';

import { generateColorList } from "../../../utils/colors";

export const useChart = (
  data,
  options = {
    shouldUpdateTotal: true
  }
) => {
  const [hiddenFields, setHiddenFields] = useState({});
  const [adaptedData, setAdaptedData] = useState([]);
  const [colors, setColors] = useState([]);

  const toggleBarVisibility = (dataKey) => {
    setHiddenFields((prevState)=> {
      return {...prevState, [dataKey]: !prevState[dataKey]};
    });
  };

  const getColors = () => {
    const startingColor = "#61A2A5"; // TODO: Agafa el color primary, posa una prop que es digui bar mainColor per el component
    // if (!data?.magnitudes?.elements?.length) {
    //   return [];
    // }

    return generateColorList(startingColor, data.magnitudes.elements.length);
  };

  const updateTotal = () => {
    // if (!data?.curve) {
    //   setAdaptedData([]);
    //   return;
    // }
    
    setAdaptedData(
      //Per cada mes o dia
      data.curve.map(date => {

        let newTotal = 0;
        Object.entries(date).map(component => {
          let componentName = component[0];
          
          // Si el camp no està amagat es suma al total
          if (!hiddenFields[componentName]) {
            if (componentName !== 'name') {
              newTotal += component[1];
            }
          }
        });

        return {...date, "total": Math.round((newTotal + Number.EPSILON) * 100) / 100};
      })
    );
  };

  useEffect(() => {
    setColors(getColors());
  }, [data]);

   useEffect(() => {
    options.shouldUpdateTotal && updateTotal();
  }, [data, hiddenFields]);
  
  return { colors, hiddenFields, adaptedData, toggleBarVisibility };
}