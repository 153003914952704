import { loadStripe } from '@stripe/stripe-js';
import { saveAs } from "file-saver";
import { CREATE_PAYMENT_PLATFORM_REQUEST, CREATE_PAY_REQUEST, RESET_PAYMENT_STATUS, SET_AVAILABLE_PAYMENT_PLATFORMS, SET_DOWNLOADING_REPORT, SET_PAYING_ITEM_ID, SET_PAYMENT_PLATFORM, SET_PAYMENT_PLATFORM_ERROR, SET_PAYMENT_PLATFORM_SUCCESS, SET_PAYMENT_RELATED_OBJECT_TYPE } from '../constants/index';
import { data_create_api_resource, data_download_api_resource, data_fetch_api_resource } from '../utils/http_functions';
import Cookies from 'js-cookie';


// PAYMENT PLATFORM PERMISSION
export function receiveAvailablePaymentPlatforms(availabilityData) {
    return {
        type: SET_AVAILABLE_PAYMENT_PLATFORMS,
        payload: availabilityData
    };
}

export function fetchAvailablePaymentPlatforms() {
    return (dispatch) => {
        data_fetch_api_resource(null, `payment_platforms_available`, 2)
            .then((response)=> {
                const availabilityData = response.data;
                if (response.status === 200) {
                    dispatch(receiveAvailablePaymentPlatforms(availabilityData));
                } else {
                    dispatch(receiveAvailablePaymentPlatforms({}));
                }
            })
            .catch(error => {
                dispatch(receiveAvailablePaymentPlatforms({}));
            });
    };
}

// PAYMENT PLATFORM CREATION(IDEALLY EXECUTED ONCE)
export function receivePublishableKey(publishableKey, platformName) {
    const payloadData = { publishableKey, platformName }
    
    if (platformName === "stripe") {
        payloadData['stripePromise'] = loadStripe(publishableKey);
    }
    return {
        type: SET_PAYMENT_PLATFORM,
        payload: payloadData
    };
}

export function receivePaymentPlatformError(error) {
    return {
        type: SET_PAYMENT_PLATFORM_ERROR,
        payload: {
            error
        }
    };
}

export function createPaymentPlatformRequest(platformName) {
    return {
        type: CREATE_PAYMENT_PLATFORM_REQUEST,
        payload: {
            platformName,
        }
    };
}

export function fetchPaymentPlatform(platformName) {
    return (dispatch) => {
        dispatch(createPaymentPlatformRequest(platformName));
        data_fetch_api_resource(null, `publishable_key/${platformName}`, 2)
            .then((response)=> {
                const publishableKey = response.data;
                if (!publishableKey || response.status !== 200) {
                    receivePaymentPlatformError(`Error: PublishableKey is empty, enter to ERP and configure the publishableKey for the platform ${platformName}`, platformName)
                } else {
                    dispatch(receivePublishableKey(publishableKey, platformName))
                }
            })
            .catch(error => {
                receivePaymentPlatformError(error.response.data, platformName)
            });
    };
}

// PAY ACTIONS
export function setPayingItemId(payingItemId) {
    Cookies.set("payingItemId", payingItemId); // Es una cookie de sessio

    return {
        type: SET_PAYING_ITEM_ID,
        payload: payingItemId
    }
}
export function setPaymentRelatedObjectType(paymentRelatedObjectType) {
    Cookies.set("paymentRelatedObjectType", paymentRelatedObjectType); // Es una cookie de sessio
    return {
        type: SET_PAYMENT_RELATED_OBJECT_TYPE,
        payload: paymentRelatedObjectType
    }
}

export function receivePayError(error) {
    return {
        type: SET_PAYMENT_PLATFORM_ERROR,
        payload: {
            error
        }
    };
}

export function receivePaySuccess(successMsg) {
    Cookies.remove("payingItemId"); // Nomes quan es success treiem la cookie, ja que pots tornar a intentar pagar i nomes poses la cookie en el setPayingItemId(clicant al button pertinent)
    Cookies.remove("paymentRelatedObjectType");
    return {
        type: SET_PAYMENT_PLATFORM_SUCCESS,
        payload: {
            success: successMsg
        }

    };
}

export function createPayRequest() {
    return {
        type: CREATE_PAY_REQUEST,
    }
}

export function resetPaymentStatus() {
    return {
        type: RESET_PAYMENT_STATUS,
    }
}

export function onlinePayment({platformName, paymentMethodId, payedItemId, relatedObject, requiredActionCallback}) {
    return async (dispatch, getState) => {

        dispatch(createPayRequest());

        let endpointParams;
        if (platformName === "stripe") {
            endpointParams = { 
                payment_method_id: paymentMethodId,
                platform_name: "stripe",
                item_id: payedItemId, 
                related_object_type: relatedObject
            };
        }

        if (!endpointParams) {
            throw new Error(`Specified platform name not implemented at the pay action, platformName:${platformName}`);
        }

        return new Promise((resolve, reject) => {
            data_create_api_resource(null, "pay", endpointParams , 2)
            .then((res) => res.data)
            .then((data) => { 
                console.debug("ONLINE PAYMENT  SUCCESS", data)
                if (data.error) {
                    dispatch(receivePayError(data.error.response.data));
                } else if (data.requires_action) {
                    requiredActionCallback && requiredActionCallback(data);
                } else {
                    dispatch(receivePaySuccess(data["success"]));
                }
                resolve(data)
             })
            .catch((reason) => {
                console.debug("ONLINE PAYMENT ERROR", reason);
                dispatch(receivePayError(reason.response.data));
                reject(reason)
            });
        });
    } 
}

export function confirmPayment({paymentId, platformName, requiredActionCallback}) {
    return async (dispatch, getState) => {
        dispatch(createPayRequest());

        let endpointParams;
        if (platformName === "stripe") {
            endpointParams = { payment_intent_id: paymentId, platform_name: "stripe" };
        }

        if (!endpointParams) {
            throw new Error(`Specified platform name not implemented in the action, platformName:${platformName}`);
        }
        return new Promise((resolve, reject) => {
            data_create_api_resource(null, "pay", endpointParams, 2)
            .then((res) => res.data)
            .then((data) => { 
                console.debug("COFIRM PAYMENT SUCCESS", data);
                if (data.error) {
                    dispatch(receivePayError(data.error.response.data));
                } else if (data.requires_action) {
                    requiredActionCallback && requiredActionCallback(data);
                } else {
                    dispatch(receivePaySuccess(data["success"]));
                }
                resolve(data)
             })
            .catch((reason) => {
                console.debug("COFIRM PAYMENT ERROR", reason);
                dispatch(receivePayError(reason.response.data));
                reject(reason)
            });
        });
    } 
}



// SUMMARY ACTIONS
export function getPaymentSummary(invoice_id) {
    // TODO: M'agradaria fer-ho mes generic, i passar la transaction_id en comptes del invoice_id, pero per tema de dades i temps no ho he pogut fer, fer-ho quan tinguem temps, involucra ERP i API
    return (dispatch) => {
        dispatch(createPayRequest());
        data_fetch_api_resource(null, `transaction_summary/${invoice_id}`, 2) 
            .then(response => response.data)
            .then(summary => { 
                if (summary === "internal_error"){
                    dispatch(receivePaySuccess({"error": summary}))
                } else {
                    dispatch(receivePaySuccess(summary))
                }
                
            })
            .catch(error => {
                dispatch(receivePaySuccess({"error": true}))
            })
        };
}

export function setDownloadingReport(newState) {
    return {
        type: SET_DOWNLOADING_REPORT,
        payload: {
            downloadingReport: newState
        }
    }
}

export function downloadTransactionReport(invoiceId) {
    return (dispatch) => {
        dispatch(setDownloadingReport(true));
        data_download_api_resource(null, `download_transaction_receipt/${invoiceId }`, 2)
            .then(response => {
                const filename = response.headers["content-disposition"].split("=");
                saveAs(response.data, filename[1]);
            })
            .catch(error => {
            })
            .finally(() => {
                dispatch(setDownloadingReport(false));
            });
    };
}
