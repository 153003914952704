import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPayment, onlinePayment, receivePayError } from '../../../actions/payment';
import ErrorPaymentDialogContent from '../CheckoutFormComps/ErrorPaymentDialogContent';
import FetchingPaymentDialogContent from '../CheckoutFormComps/FetchingPaymentDialogContent';
import FillPaymentDialogContent from '../CheckoutFormComps/FillPaymentDialogContent';
import SuccessPaymentDialogContent from '../CheckoutFormComps/SuccessPaymentDialogContent';
import StripeElements from './StripeElements';
import { PaymentPlatformContext } from '../PaymentPlatformProvider';

/*
 * Feature component
 *
 * Responsible to render the dialog content depending of the payment state.
 *
 * - Responsible to handle the submit of the payment form.
 * - Responsible to autohandle the confirmation of 3dsecure payments.
 * - Responsible to handle the server response of the payment request
 * - Responsible to render the components:
 *  - `FillPaymentDialogContent` rendered by default, passing the `handleSubmit` and `onClose` props.
 *  - `FetchingPaymentDialogContent` render if the payment reducer has `isFetching`.
 *  - `ErrorPaymentDialogContent` rendered if the payment reducer has `error`, passing the `onClose` props.
 *  - `SuccessPaymentDialogContent` rendered if the payment reducer has `success`, passing the `onClose` props.
*/
export default function CheckoutForm({}) {
  const dispatch = useDispatch();
  const { 
    isFetching, 
    error, 
    success, 
    payingItemId,
    paymentRelatedObjectType
  } = useSelector((state) => state.payment);
  const stripe = useStripe();
  const elements = useElements();
  const { handlePaymentSuccess, handlePaymentError, itemId } = useContext(PaymentPlatformContext);

  
  useEffect(() => { 
    if (!stripe)
      return
    // Used when is a redirection (example: from a confirmation redirection)
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get("payment_intent");
    if (paymentIntentId) { // Url param has an intent, confirm again and clear the url params.
      confirmPaymentIntent(paymentIntentId);
      // Remove all parameters from the URL, because we don't want to confirm again this payment
    window.history.replaceState(null, '', window.location.pathname); // Borra els params per evitar crides descuidades
      // const newUrl = `${window.location.pathname}`;
      // window.history.replaceState(null, "", newUrl);
    }
  }, [stripe]); // Quan tingui stripe mirarà el location.search


  const redirectToUserConfirmation = async (response) => {
    // User has to some extra action, stripe will do that for us and return the response
    // Use Stripe.js to handle the required card action
    const { error: errorAction, paymentIntent } = await stripe.handleCardAction(response.payment_intent_client_secret);
    // El punt de sobre fa un redirect o s'espera a que l'usuari accepti desde la app del banc.
    // En el cas de redirect el useEffect serà el que agafarà dela url els parametres i cridarà la
    // funció confirmPaymentIntent
    
    if (errorAction) {
      dispatch(receivePayError(errorAction));// Note: I'm not sure what the return is for errorAction. If any error message fails, review this line.
      handlePaymentError({'error': errorAction});
    } else {
      // The card action has been handled
      confirmPaymentIntent(paymentIntent.id);
    }
  }

  const confirmPaymentIntent = (paymentIntentId) => {
    // The PaymentIntent can be confirmed again on the server
    dispatch(confirmPayment({
      "paymentId": paymentIntentId,
      "platformName": "stripe",
      //"requiredActionCallback": redirectToUserConfirmation
      // En ppi no cal el codi de sobre ja que se suposa que ja sha confirmat, si s'activa
      // hi ha algun cas raro el, que podria passar en el pitjor del casos
      // es un bucle infinit(mai surt del flow de confirmar, i tornar a confirmar), 
      // i l'usuari es cansara i tancara.
    })).then((data) => handlePaymentSuccess(data))
      .catch((reason) => handlePaymentError(reason))
  }
  const pay = (paymentMethodId) => {
    // You pass the paymentMethodId can be paid in the Backend
      dispatch(
        onlinePayment({
          "paymentMethodId": paymentMethodId,
          "relatedObject": paymentRelatedObjectType,
          "payedItemId": payingItemId,
          "requiredActionCallback": redirectToUserConfirmation,
          "platformName": "stripe"
        })
      ).then((data) => handlePaymentSuccess(data))
       .catch((reason) => handlePaymentError(reason));
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements || isFetching) { // Stripe.js hasn't yet loaded or is fetching something.
      return;
    }

    // This will collect the card details and create a PaymentMethod, more info: https://docs.stripe.com/js/payment_methods/create_payment_method
    const result = await stripe.createPaymentMethod({
      "type": 'card',
      card: elements.getElement("cardNumber"),
    });

    if (result.error) {
      handlePaymentError({'error': result.error});
      dispatch(receivePayError(result.error));
    } else {
      pay(result.paymentMethod.id);
    }
  };


  let drawedDialogContent = (
    <FillPaymentDialogContent 
      onSubmit={handleSubmit} 
      dialogContent={<StripeElements />} 
      />
  )
  if (isFetching) {
    drawedDialogContent = <FetchingPaymentDialogContent/>
  } else if (error) {
    drawedDialogContent = <ErrorPaymentDialogContent/>
  } else if (success) {
    drawedDialogContent = <SuccessPaymentDialogContent/>
  }

  return drawedDialogContent
}
