import { CREATE_PAYMENT_PLATFORM_REQUEST, CREATE_PAY_REQUEST, RESET_PAYMENT_STATUS, SET_AVAILABLE_PAYMENT_PLATFORMS, SET_DOWNLOADING_REPORT, SET_PAYING_ITEM_ID, SET_PAYMENT_RELATED_OBJECT_TYPE, SET_PAYMENT_PLATFORM, SET_PAYMENT_PLATFORM_ERROR, SET_PAYMENT_PLATFORM_SUCCESS } from '../constants';
import { createReducer } from '../utils/misc';

const initialState = {
    publishableKey: null,
    isFetching: false,
    error: null,
    success: null, // Success has a summary of the transaction
    stripePromise: null, // Must be created once, and only once
    downloadingReport: null,
    payingItemId: null, 
    paymentRelatedObjectType: null, 
    availablePaymentPlatforms: null,
};

export default createReducer(initialState, {
    [SET_AVAILABLE_PAYMENT_PLATFORMS]: (state, payload) => 
        Object.assign({}, state, {
            availablePaymentPlatforms: payload,
        }),
    [SET_PAYMENT_PLATFORM]: (state, payload) =>
        Object.assign({}, state, {
            publishableKey: payload.publishableKey,
            stripePromise: payload.stripePromise,
            isFetching: false,
            error: null,
            success: null,
        }),
    [CREATE_PAYMENT_PLATFORM_REQUEST]: (state, payload) => 
        Object.assign({}, state, {
            publishableKey: null,
            stripePromise: null,
            isFetching: true,
            error: null,
            success: null,
        }),
    [SET_PAYMENT_PLATFORM_ERROR]: (state, payload) => 
        Object.assign({}, state, {
            isFetching: false,
            error: payload.error,
            success: false,
        }),
    [SET_PAYING_ITEM_ID]: (state, payload) => 
        Object.assign({}, state, {
            payingItemId: payload
        })
    ,
    [SET_PAYMENT_RELATED_OBJECT_TYPE]: (state, payload) => 
        Object.assign({}, state, {
            paymentRelatedObjectType: payload
        })
    ,
    [CREATE_PAY_REQUEST]: (state, payload) => 
        Object.assign({}, state, {
            isFetching: true,
            error: null,
            success: null,
        }),
    [SET_PAYMENT_PLATFORM_SUCCESS]: (state, payload) => 
        Object.assign({}, state, {
            isFetching: false,
            error: null,
            success: payload.success,
            payingItemId: null,
            paymentRelatedObjectType: null, 
        }),
    [RESET_PAYMENT_STATUS]: (state, payload) => 
        Object.assign({}, state, {
            isFetching: false,
            error: null,
            success:null, 
        }),
    [SET_DOWNLOADING_REPORT]: (state, payload) => 
        Object.assign({}, state, {
            downloadingReport: payload.downloadingReport
        })
    
});
