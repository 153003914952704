import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setPayingItemId, setPaymentRelatedObjectType } from "../../actions/payment";

/*
 * Feature component
 * Responsible for rendering the payment button.
 *
 * - Calls the set of payingItemId reducers actions.
 * - Hides the button if the platformName is not available.
*/
const PayButton = ({payableDataId, platformName, relatedObjectType, children}) => {
    const dispatch = useDispatch();
    const availablePaymentPlatforms = useSelector((state) => state.payment.availablePaymentPlatforms);

    const handleClick = () => {
        console.debug("hola", payableDataId, relatedObjectType)
        dispatch(setPayingItemId(payableDataId))
        dispatch(setPaymentRelatedObjectType(relatedObjectType))
    }
    return <>
        {
            (availablePaymentPlatforms && availablePaymentPlatforms[platformName]) && (
                <Button color={'primary'} variant={'contained'} onClick={handleClick}> 
                    { children }
                </Button>
            )
        }
    </>
}

export default PayButton;