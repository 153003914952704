import { useTranslation } from "react-i18next";
import PaymentPaidSummaryButton from "./PaymentPaidSummaryButton";
import PaymentPendingStateHistoryButton from "./PaymentPendingStateHistoryButton";


const PaymentStatus = ({invoice}) => {
  const { i18n } = useTranslation();
	let paid = null;
	console.debug(invoice.paid, invoice.amount_pending, invoice.amount_total)
	if (invoice.paid === "paid_without_transaction") {
		paid = <strong>{i18n.t('common:text.invoice_paid')}</strong>;
	} else if (invoice.paid === "manual_verification_required") {
		paid = (
			<PaymentPaidSummaryButton invoice={invoice}>
				{i18n.t('common:text.invoice_paid')}
			</PaymentPaidSummaryButton> 
		)
	} else if (invoice.paid === true) {
		if (invoice.rectificative_type === "A") {
			paid = <strong>{i18n.t('common:text.invoice_refunded')}</strong>
		}
		else {
			paid = (
				<PaymentPaidSummaryButton invoice={invoice}>
					{i18n.t('common:text.invoice_paid')}
				</PaymentPaidSummaryButton> 
			)
		}
	} else if (invoice.paid === false) {
		paid = <strong>{i18n.t('common:text.invoice_not_paid')}</strong>;

		if (invoice.amount_pending) {
			if (invoice.amount_pending != invoice.amount_total) {
				paid = <strong>{i18n.t('common:text.invoices_parcial_payment')}</strong>;
			} 
			// We enable a link to show the pending state history only if there is more than one item to show.
			if (invoice.pending_state_history && invoice.pending_state_history.length > 1) {
			 	paid = (
					<PaymentPendingStateHistoryButton invoice={invoice} >
						{ paid }
					</PaymentPendingStateHistoryButton>
				)
			}
		}
	}  

	return paid;
}

export default PaymentStatus;
